<template>
  <div>

    <!-- 제목 -->
    <div class="semantics" :count="pagination.total">
      <h1>{{ semantics.h1 }}</h1>
      <h2>{{ semantics.h2 }}</h2>
    </div>

    <!-- 검색 -->
    <component
      :is="MelodySearch"
      :resolution="resolution"
      :pagination="pagination"
      @updateYearToAge="updateYearToAge"
      @onSearch="onSearch"
      @clearAge="clearAge"
    />

    <!-- 결과없음 -->
    <div v-if="!items.length" :pd="resolution.width > resolution.notebook ? `4xl` : `md`">
      <Empty
        :message="$t('empty-default-message')"
        :extent="`w-100`"
        :fsize="`sm`"
        :bg="`5xs`"
      />
    </div>

    <!-- 목록 -->
    <component
      v-else
      :is="MelodyItemList"
      :items="items"
    />

    <!-- 페이지 -->
    <component
      :is="Pagination"
      :pagination="pagination"
      :resolution="resolution"
      :key="`${pagination.page}-page`"
    />
    
  </div>
</template>

<script lang="ts">
import { storeToRefs } from 'pinia'
import { useGlobalStore } from '~/store/global'
import { APICALL } from '~/utils/api'
// import MelodySearch from '~/component/game/melody/search.vue'
// import MelodyItemList from '~/component/game/melody/list.vue'
// import Pagination from '~/component/element/pagination.vue'

export default {
  name: 'index',
  async setup () {
    // 레이아웃 설정
    definePageMeta({
      layout: "default"
    })

    // 기본
    const route = useRoute()
    const $i18n: any = ref(globalVariables.app.i18n)
    const semantics: any = ref({ h1:'', h2: '' })

    // 데이터 설정
    const { error, popstate, resolution, navigator } = storeToRefs(useGlobalStore())
    
    const metatag: any = ref('')
    const items: any = ref([])
    const pagination: any = ref(paginationFunction.setPagination(route, `melody`, `/`, ['keyword']))

    // 추가 데이터 설정
    pagination.value.keyword = route.query.keyword || ''

    // 필터 체크
    function updateSearchFilter () {
      pagination.value.filter = Boolean(
        (pagination.value.country && pagination.value.country.length) || 
        (pagination.value.genre && pagination.value.genre.length) || 
        pagination.value.yearModel || 
        pagination.value.age
      )
    }

    // 검색 문자열 업데이트 ( 다국어 목적 )
    function updateSearchValue () {
      pagination.value.countries = globalFunctions.getKeyValueTranslate('country', 'code', 'text', globalVariables.country)
      pagination.value.genres = globalFunctions.getKeyValueTranslate('genre', 'code', 'text', globalVariables.genre)
      pagination.value.ages = globalFunctions.getKeyValueTranslate('age', 'code', 'text', globalVariables.age)
    }

    // 메모리 비우기
    async function restore () {
      MelodySearch.value = null
      MelodyItemList.value = null
      Pagination.value = null
      $i18n.value = null
      semantics.value = null
      metatag.value = null
      items.value = null
      pagination.value = null
      api.value = null
    }

    //
    onBeforeUnmount ( async () => {
      await restore()
    })

    // API 정의
    const api: any = ref({})
    async function getMelodyList (page: any = 1) {

      // 페이지
      pagination.value.busy = true
      pagination.value.q = route.query.q || ''
      pagination.value.country = ref(route.query.country || navigator.value.country)
      pagination.value.genre = ref(route.query.genre || null)
      pagination.value.yearModel = ref(route.query.year ? true : false)
      pagination.value.year = ref(route.query.year || globalFunctions.getYearCenter())
      pagination.value.years = ref(globalFunctions.getYearStep())
      pagination.value.age = ref(globals.toNumber(route.query.age) || null)
      pagination.value.filter = ref(false)
      pagination.value.params = {
        ...route.query,
        size: pagination.value.size,
        page: page
      }

      updateSearchFilter()
      updateSearchValue()
      
      // API 값 설정
      api.value.params = {
        ...pagination.value.params
      }

      // 값 파싱 ( 연도 분기 )
      if (api.value.params.year) {
        api.value.params.year_start = api.value.params.year[0]
        api.value.params.year_end = api.value.params.year[1]
        delete api.value.params.year
      }

      api.value.payload = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'X-Requested-With': 'XMLHttpRequest',
          'Authorization': `Bearer ${globalStores.getAccessToken()}`
        }
      }

      api.value.address = `/game/melody/get-list`
      api.value.DATA = await APICALL(api.value.address, api.value.payload, api.value.params, false, true, false)
      if (api.value.DATA?.result?.error?.code === 'expired_token') {
        await globalTokens.isToken()
        api.value.payload.headers.Authorization = `Bearer ${globalStores.getAccessToken()}`
        api.value.DATA = await APICALL(api.value.address, api.value.payload, api.value.params, false, true, false)
      } else if (api.value.DATA?.result?.type === 'cors' && api.value.DATA?.result?.status === 500) {
        /*
        ** 토큰이 꼬였을 때
        ** 토큰을 우선 제거하고
        */
        globalFunctions.removeToken()

        // 다시 발행받으면서 아이템 조회한다.
        await globalTokens.isToken()
        api.value.payload.headers.Authorization = `Bearer ${globalStores.getAccessToken()}`
        api.value.DATA = await APICALL(api.value.address, api.value.payload, api.value.params, false, true, false)
      }
      // 결과
      if (api.value.DATA?.result?.success) {
        items.value = api.value.DATA?.result?.data?.melodies || []
        paginationFunction.updatePagination(api.value.DATA, pagination.value, page)
        paginationFunction.endOfOnlist()
      }

      api.value.loaded = true
      setMetatag()
    }
    // 스토리지 데이터가 없을때만 API 호출
    api.value.storageData = paginationFunction.getStorageRendering(pagination.value.key, ['items'])
    if (!api.value.storageData) {
      await getMelodyList(route.query.page)
    } else {
      setMetatag()
    }

    // 메타태그 설정
    function setMetatag () {
      let page = api.storageData?.pagination || pagination.value
      metatag.value = ''
      metatag.value += page.q ? `${page.q}` : `${$i18n.value.$t('site-name')}`
      metatag.value += page.age ? ` | ${$i18n.value.$t(`age`)} : ${$i18n.value.$t(`age-${page.age}`)}` : ``
      metatag.value += page.year ? ` | ${$i18n.value.$t(`year`)} : ${page.year}` : ``
      metatag.value += page.country ? ` | (${page.country})` : ``
      metatag.value += ` ${$i18n.value.$t('base-result')} - ${page.page}${$i18n.value.$t('page')} | ${$i18n.value.$t('site-name')} - ${$i18n.value.$t('site-short-name')}`
      if (route.fullPath === '' || route.fullPath === '/') {
        useHead(
          globalFunctions.getMetatag({
            // 기본 데이터 삽입
          }, route, $i18n.value)
        )
      } else {
        useHead(
          globalFunctions.getMetatag({
            lang: $i18n.value.$i18n.locale,
            titleText: metatag.value,
            keyword: pagination.value.q
          }, route, $i18n.value)
        )
      }
      semantics.value = {
        h1: metatag.value,
        h2: pagination.value.q
      }

      // 메모리 제거
      page = null
    }

    // 비동기 함수를 만들어서 컴포넌트를 로딩 ---------------------------------------------------------
    const MelodySearch: any = shallowRef(null)
    const MelodyItemList: any = shallowRef(null)
    const Pagination: any = shallowRef(null)
    const loadComponent = async () => {
      const MelodySearch_load = await import('~/component/game/melody/search.vue')
      const MelodyItemList_load = await import('~/component/game/melody/list.vue')
      const Pagination_load = await import('~/component/element/pagination.vue')
      MelodySearch.value = MelodySearch_load.default || MelodySearch_load
      MelodyItemList.value = MelodyItemList_load.default || MelodyItemList_load
      Pagination.value = Pagination_load.default || Pagination_load
    }
    await loadComponent()
    // ---------------------------------------------------------------------------------------------

    return {
      semantics,
      error,
      popstate,
      api,
      items,
      pagination,
      resolution,
      updateSearchFilter,
      updateSearchValue,
      getMelodyList,

      // 컴포넌트
      MelodySearch,
      MelodyItemList,
      Pagination
    }
  },
  // components: {
  //   MelodySearch,
  //   MelodyItemList,
  //   Pagination
  // },
  watch: {
    'pagination.country' () {
      this.updateSearchFilter()
    },
    'pagination.genre' () {
      this.updateSearchFilter()
    },
    'pagination.yearModel' (to) {
      this.updateSearchFilter()
      if (!to) {
        this.pagination.age = null
        this.$nextTick(() => {
          this.pagination.yearModel = false
        })
      }
    },
    'pagination.age' (to) {
      this.updateSearchFilter()
      if (to) {
        this.updateAgeToYear()
      }
    },
    '$i18n.locale' () {
      this.updateSearchValue()
    },
    'pagination.page' (to) {
      paginationFunction.watchPaginationPage(to)
    },
    '$route' (to, from) {
      paginationFunction.routeInterval(to, from, this.pagination, (v) => {
        if (v) {
          this.getMelodyList(to.query.page)
        }
      })
    }
  },
  beforeMount () {
    // 홈에서만 레이아웃을 한번 체크하자.
    let container: any = document.querySelector('.contents > [container="sm"]')
    if (container) {
      container.removeAttribute('class')
      container.removeAttribute('container')
      container.removeAttribute('mg')
      container.removeAttribute('pd')
      container.setAttribute('animation', 'layout-transition')
      container.setAttribute('flex', '1')
    }
    container = null

    // 스토리지 랜더링
    if (this.api.storageData) {
      // ◆ 페이지 정보와 아이템을 업데이트
      this.items = this.api.storageData.items
      this.pagination = this.api.storageData.pagination
      this.api.loaded = true

      // 데이터 변화 후 스크롤 이동
      this.$nextTick(() => {
        paginationFunction.endOfOnlist()
      })
    }
  },
  beforeRouteLeave () {
    // ◆ 스토리지 기록
    paginationFunction.setStorageData(this.pagination.key, {
      items: this.items,
      pagination: this.pagination
    })
  },
  methods: {
    // 연령대와 연도 맞추기
    updateAgeToYear () : any {
      let min: number|null = globalVariables.create.year.max - (this.pagination.age + globalVariables.create.year.leeds) + (globalVariables.create.year.leeds * 1.25)
      let max: number|null = min + (globalVariables.create.year.leeds / 3) + (this.pagination.age / 2)
      this.pagination.yearModel = true
      this.pagination.year = [Math.round(min), Math.round(max)]
      min = null
      max = null
    },
    // 연도 수동 선택시 연령 제거
    updateYearToAge () {
      if (this.pagination.age) {
        this.pagination.age = null
      }
    },
    // 연령 선택시 연도 닫기
    clearAge () {
      this.pagination.yearModel = false
      this.pagination.year = globalFunctions.getYearCenter()
    },
    // 검색
    onSearch () {
      let payload: any = {}
      if (this.pagination.q) payload.q = this.pagination.q
      if (this.pagination.country) payload.country = this.pagination.country
      if (this.pagination.genre) payload.genre = this.pagination.genre
      if (this.pagination.yearModel) payload.year = this.pagination.year
      if (this.pagination.age) payload.age = this.pagination.age
      this.$router.push({
        path: this.pagination.path,
        query: payload
      })
      payload = {}
    }
  },
  destroyed() {
    //although we are inside destroyed life cycle we can still access to 
    //this.str,this.testArr
    // console.log("destroyed")
  }
}
</script>
